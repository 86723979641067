<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <b-card>
      <b-row>
        <b-col lg="8">
          <b-form ref="firstTabForm" @submit="onSubmit" @reset="onReset">
            <!-- 订单名称 -->
            <b-form-group
              label="Order Name:"
              label-cols-lg="3"
              label-align-lg="right"
              label-for="order-name"
              label-class="requiredRow"
            >
              <b-form-input
                id="order-name"
                v-model="form.orderName"
                required
                oninvalid="setCustomValidity('Please enter');"
                oninput="setCustomValidity('')"
                placeholder="Please enter"
              ></b-form-input>
            </b-form-group>

            <!-- 订单号 -->
            <b-form-group
              label="Order No.:"
              label-cols-lg="3"
              label-align-lg="right"
              label-for="order-number"
            >
              <b-form-input
                id="order-number"
                placeholder="Automatic system generation"
                disabled
              >
              </b-form-input>
            </b-form-group>
            <!-- 主机 -->
            <b-form-group
              v-if="selectItem == 4"
              label="Production Type:"
              label-cols-lg="3"
              label-align-lg="right"
              label-for="cottonBag"
              label-class="requiredRow"
            >
              <b-form-radio-group
                id="radio-group-1"
                v-model="productionType"
                :options="types.productionType"
                name="radio-options"
                required
                style="margin-top: 5px"
              ></b-form-radio-group>
            </b-form-group>

            <!-- 批次类型 -->
            <b-form-group
              v-if="selectItem == 3 || (selectItem == 4 && productionType == 1)"
              label="Batch Type:"
              label-cols-lg="3"
              label-align-lg="right"
              label-for="batch-type"
              label-class="requiredRow"
            >
              <div class="d-flex flex-wrap">
                <b-form-checkbox
                  v-for="(label, index) in types.batchType"
                  :required="true"
                  :key="index"
                  v-model="form.batchType"
                  :value="label.id"
                  class="mr-2 mt-2"
                >
                  {{ label.typeName }}
                </b-form-checkbox>
              </div>
            </b-form-group>

            <!-- 烟弹型号 -->
            <b-form-group
              label="Cartridge Model:"
              label-cols-lg="3"
              label-align-lg="right"
              label-for="cartridge-model"
              label-class="requiredRow"
            >
              <b-form-select
                id="cartridge-model"
                v-model="form.testSmokeBombModeId"
                :options="types.selectAllTestSmokeBombModel"
                value-field="id"
                text-field="modelName"
                required
                oninvalid="setCustomValidity('Please select');"
                oninput="setCustomValidity('')"
              ></b-form-select>
            </b-form-group>
            <b-form-group
              v-if="selectItem == 3"
              label="Flavor:"
              label-cols-lg="3"
              label-align-lg="right"
              label-for="flavor-model"
            >
              <b-form-select
                id="flavor-model"
                v-model="form.smokeBombModelId"
                :options="types.flavorList"
                value-field="id"
                text-field="modelName"
                oninvalid="setCustomValidity('Please select');"
                oninput="setCustomValidity('')"
              ></b-form-select>
            </b-form-group>

            <b-form-group
              v-if="selectItem == 4"
              label="Flavor:"
              label-cols-lg="3"
              label-align-lg="right"
              label-for="flavor-model"
              label-class="requiredRow"
            >
              <b-form-select
                id="flavor-model"
                v-model="form.smokeBombModelId"
                :options="types.flavorList"
                value-field="id"
                text-field="modelName"
                required
                oninvalid="setCustomValidity('Please select');"
                oninput="setCustomValidity('')"
              ></b-form-select>
            </b-form-group>

            <!-- 膏弹类型：默认加热模式 -->
            <b-form-group
              v-if="selectItem == 2"
              label="Default Heating mode:"
              label-cols-lg="3"
              label-align-lg="right"
              label-for="heating-model"
              label-class="requiredRow"
            >
              <b-form-select
                id="heating-model"
                v-model="form.defaultWarmHeatingId"
                :options="types.selectAllDefaultHeatingMode"
                value-field="id"
                text-field="modeName"
                required
                oninvalid="setCustomValidity('Please select');"
                oninput="setCustomValidity('')"
              ></b-form-select>
            </b-form-group>
            <!-- 膏弹类型：默认预热模式 -->
            <b-form-group
              v-if="selectItem == 2"
              label="Default Pre-heat Mode:"
              label-cols-lg="3"
              label-align-lg="right"
              label-for="warmup-model"
              label-class="requiredRow"
            >
              <b-form-select
                id="warmup-model"
                v-model="form.defaultWarmUpId"
                :options="types.selectAllDefaultWarmUpMode"
                value-field="id"
                text-field="warmUpName"
                required
                oninvalid="setCustomValidity('Please select');"
                oninput="setCustomValidity('')"
              ></b-form-select>
            </b-form-group>
            <!-- 生产数量 -->
            <b-form-group
              label="Production Quantity:"
              label-cols-lg="3"
              label-align-lg="right"
              label-for="production-quantity"
              label-class="requiredRow"
            >
              <b-form-input
                id="production-quantity"
                v-model="form.batchQuantity"
                type="number"
                min="0"
                required
                oninvalid="setCustomValidity('Please enter');"
                oninput="setCustomValidity('')"
                placeholder="Please Enter"
              ></b-form-input>
              <span v-if="selectItem != 1">
                {{ quantity }} box,The recommended quantity is a multiple of 50,
                one box per 50</span
              >
            </b-form-group>
            <!-- 生产品牌 -->
            <!-- <b-form-group
              label="Production Brand:"
              label-cols-lg="3"
              label-align-lg="right"
              label-for="production-brand"
              label-class="requiredRow"
            >
            <b-form-select
							id="production-brand"
							v-model="selected"
							:options="types.brand"
              value-field="value"
              text-field="text"
							required
							oninvalid="setCustomValidity('Please select');"
							oninput="setCustomValidity('')"
						></b-form-select>
            </b-form-group> -->

            <!-- 最大吸入秒数 -->
            <b-form-group
              v-if="selectItem == 2"
              label="Upper Limit for Vaporization Time in Seconds:"
              label-cols-lg="3"
              label-align-lg="right"
              label-for="maxFrequency"
              label-class="requiredRow"
              description="To Prevent Counterfeit by Refill, Cartridge Will Stop Working After Time Reaches."
            >
              <b-form-input
                id="maxFrequency"
                v-model="form.maxFrequency"
                type="number"
                min="0"
                required
                oninvalid="setCustomValidity('Please enter');"
                oninput="setCustomValidity('');value = value.replace(/[^\d.]/g, '').replace(/\.{2,}/g,'.').replace(/^\./g, '').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3');if(value>60000)value=60000;if(value<1)value=1"
                placeholder="Please Enter"
              ></b-form-input>
            </b-form-group>
            <!-- PCBA供应商 -->
            <b-form-group
              label="PBCA Supplier:"
              label-cols-lg="3"
              label-align-lg="right"
              label-for="pbca-supplier"
              label-class="requiredRow"
            >
              <b-form-input
                id="pbca-supplier"
                v-model="form.pcbaSupplier"
                type="text"
                min="0"
                maxlength="250"
                required
                oninvalid="setCustomValidity('Please enter');"
                oninput="setCustomValidity('');"
                placeholder="Please Enter"
              ></b-form-input>
            </b-form-group>
            <!-- 本次PCBA负责人 -->
            <b-form-group
              label="Charger of PCBA:"
              label-cols-lg="3"
              label-align-lg="right"
              label-for="charger-of-pcba"
              label-class="requiredRow"
            >
              <b-form-input
                id="charger-of-pcba"
                v-model="form.chargerOfPcba"
                type="text"
                min="0"
                maxlength="250"
                required
                oninvalid="setCustomValidity('Please enter');"
                oninput="setCustomValidity('')"
                placeholder="Please Enter"
              ></b-form-input>
            </b-form-group>
            <!-- 组装供应商 -->
            <b-form-group
              label="Assembly Supplier:"
              label-cols-lg="3"
              label-align-lg="right"
              label-for="assembly-supplier"
              label-class="requiredRow"
            >
              <b-form-input
                id="assembly-supplier"
                v-model="form.assemblySupplier"
                type="text"
                min="0"
                maxlength="250"
                required
                oninvalid="setCustomValidity('Please enter');"
                oninput="setCustomValidity('')"
                placeholder="Please Enter"
              ></b-form-input>
            </b-form-group>
            <!-- 本次组装责任人 -->
            <b-form-group
              label="Charger of Assembly:"
              label-cols-lg="3"
              label-align-lg="right"
              label-for="charger-of-assembly"
              label-class="requiredRow"
            >
              <b-form-input
                id="charger-of-assembly"
                v-model="form.chargerOfAssembly"
                type="text"
                min="0"
                maxlength="250"
                required
                oninvalid="setCustomValidity('Please enter');"
                oninput="setCustomValidity('')"
                placeholder="Please Enter"
              ></b-form-input>
            </b-form-group>
            <!-- 最大吸入秒数 -->
            <!-- v-if="selectItem == 3 || selectItem == 4" -->
            <b-form-group
              v-if="false"
              label="Max seconds:"
              label-cols-lg="3"
              label-align-lg="right"
              label-for="maxFrequency"
              label-class="requiredRow"
              description="To Prevent Counterfeit by Refill, Cartridge Will Stop Working After Time Reaches."
            >
              <b-form-input
                id="maxFrequency"
                v-model="form.maxFrequency"
                type="number"
                min="0"
                required
                oninvalid="setCustomValidity('Please enter');"
                oninput="setCustomValidity('');value = value.replace(/[^\d.]/g, '').replace(/\.{2,}/g,'.').replace(/^\./g, '').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3');if(value>60000)value=60000;"
                placeholder="Please Enter"
              ></b-form-input>
            </b-form-group>

            <!-- v-if="selectItem == 3 || selectItem == 4" -->
            <b-form-group
              v-if="false"
              label="Voltage:"
              label-cols-lg="3"
              label-align-lg="right"
              label-for="curveOne"
              label-class="requiredRow"
            >
              <div class="voltage-one">
                <div
                  class="voltage-number"
                  v-for="(item, index) in outputList"
                  :key="index"
                >
                  {{ item.value }} v
                </div>
              </div>

              <div class="voltage-two" style="width: 100%">
                <!--               @touchmove.prevent="(e)=>{}" -->

                <div
                  class="voltage-list"
                  v-for="(item, index) in outputList"
                  :key="index"
                >
                  <i
                    class="el-icon-plus voltage-plus"
                    @click="onVoltagePlus(index)"
                  ></i>

                  <el-slider
                    v-model="item.value"
                    vertical
                    :step="step"
                    :min="min"
                    :max="max"
                    height="150px"
                  >
                  </el-slider>

                  <i
                    class="el-icon-minus voltage-reduce"
                    @click="onVoltageReduce(index)"
                  ></i>
                </div>
              </div>

              <div class="voltage-three">
                <div class="voltage-number" v-for="index of 8" :key="index">
                  {{ index }}s
                </div>
              </div>
              <p>
                The Voltage curve is between 0.1v-4.2v, after the 8th second,
                the same as the 8th second
              </p>
            </b-form-group>

            <!-- 六点输出 -->
            <!-- <b-form-group
              v-if="selectItem == 3 || selectItem == 4"
              label="Voltage curve:"
              label-cols-lg="3"
              label-align-lg="right"
              label-for="heatting-temp"
              label-class="requiredRow"
            >
              <b-form-input
                id="heatting-temp"
                v-model="form.output"
                type="text"
                min="0"
                maxlength="250"
                required
                oninvalid="setCustomValidity('Please Enter six point temperature ');"
                oninput="setCustomValidity('')"
                placeholder="Please Enter six point temperature "
              ></b-form-input>
            </b-form-group> -->
            <!-- <b-form-group
              v-if="selectItem == 3"
              label="Voltage curve :"
              label-cols-lg="3"
              label-align-lg="right"
              label-for="output"
              label-class="requiredRow"
              description="For Voltage curve value, enter the actual voltage value multiplied by 1000. For example, if it is 2.4V, enter 2400,please fill in between 100 and 4200"
            >
              <span
                v-for="(item, index) in outputList"
                :key="index"
                class="col-md-2 col-2"
                style="display: inline-block; padding-left: 0"
              >
                <label>{{ index + 1 + " " + "seconds" }}</label>
                <b-form-input
                  v-model="item.value"
                  type="number"
                  min="100"
                  step="100"
                  placeholder="Please enter"
                  title=""
                  required
                  @change="changeoutputList(outputList)"
                  oninvalid="setCustomValidity('Please enter')"
                  oninput="setCustomValidity('');value = value.replace(/[^\d.]/g, '').replace(/\.{2,}/g,'.').replace(/^\./g, '').replace('.', '$#$').replace(/\./g, '').replace('$#$', '.').replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3')"
                  onblur="if(value<100||value>4200)value=100"
                ></b-form-input>
              </span>
            </b-form-group> -->
            <!-- 生产厂家 -->
            <b-form-group
              label="Manufacturer:"
              label-cols-lg="3"
              label-align-lg="right"
              label-for="manufacturer"
            >
              <!-- <b-form-select
                id="manufacturer"
                v-model="form.manufacturerEncodingName"
                :options="types.manufacturerEncodingList"
                value-field="id"
                text-field="name"
              ></b-form-select> -->
              <b-form-input
                id="manufacturer"
                v-model="form.manufacturerEncodingName"
                placeholder="Please Enter"
              ></b-form-input>
            </b-form-group>

            <!-- 生产时间 -->
            <b-form-group
              label="Production Time:"
              label-cols-lg="3"
              label-align-lg="right"
              label-for="production-time"
            >
              <date-picker
                id="production-time"
                v-model="productionTime"
                format="MM/DD/YYYY"
                append-to-body
                lang="en"
                placeholder="Select date"
              ></date-picker>
            </b-form-group>

            <!-- 备注 -->
            <b-form-group
              label="Remarks:"
              label-cols-lg="3"
              label-align-lg="right"
              label-for="remarks"
            >
              <b-form-textarea
                id="remarks"
                v-model="form.remarks"
                placeholder="Other Notes"
                rows="3"
                max-rows="6"
              ></b-form-textarea>
            </b-form-group>

            <b-col lg="12" style="text-align: center">
              <b-button type="submit" variant="primary">Confirm</b-button>
              <b-button type="reset" style="margin-left: 30px">Cancel</b-button>
            </b-col>
          </b-form>
        </b-col>
        <!-- end col -->
      </b-row>
    </b-card>
  </Layout>
</template>
<script>
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import HistoryCurve from "@/components/HistoryCurve.vue";

/**
 * Cartridge Batch Add component
 */

export default {
  components: {
    Layout,
    PageHeader,
    DatePicker,
    HistoryCurve,
    Multiselect,
  },
  computed: {
    is_add: function () {
      return /add/i.test(location.href);
    },
    //每50个一盒
    quantity() {
      var number = 0;
      if (this.form.batchQuantity > 0 && this.form.batchQuantity < 50) {
        return 1;
      } else {
        number = this.form.batchQuantity / 50;
        if (this.form.batchQuantity % 50 != 0) {
          number = number + 1;
        }
        return Math.floor(number);
      }
    },
  },
  created() {
    this.selectItem = this.$route.query.selectItem;
    this.init();
  },
  watch: {
    productionTime(val) {
      this.form.productionTime = this.dateFormatter(val);
      console.log(this.form.productionTime);
    },
  },
  data() {
    return {
      selectItem: 0, //类型(1是油弹 2是膏弹 3是电池烟弹 4是电磁烟弹 5是NIC)
      title: "Empty Carts Orders Create",
      items: [
        {
          text: "UNI-CORE",
          // href: "/"
        },
        {
          text: "Production Mgmt",
          // href: "/"
        },
        {
          text: "Empty Carts Orders Create",
          active: true,
        },
      ],
      types: {
        // manufacturerEncodingList: [{ name: "Please select", id: "" }],
        selectAllTestSmokeBombModel: [{ modelName: "Please select", id: "" }],
        selectAllDefaultHeatingMode: [{ modeName: "Please select", id: "" }],
        selectAllDefaultWarmUpMode: [{ warmUpName: "Please select", id: "" }],
        flavorList: [{ modelName: "Please select", id: "" }],
        productionType: [
          { text: "Device", value: 1 },
          { text: "Cartridge", value: 2 },
        ],
        batchType: [
          { typeName: "Scan code", id: 1 },
          { typeName: "No Scan code", id: 2 },
        ],
        // brand: [
        //   { value: 0, text: 'Please select an option'},
        //   {
        //     label: 'AVD',
        //     options: [
        //       { value: '03', text: 'GDC' },
        //       { value: '04', text: 'Stem' },
        //       { value: '05', text: '2ML' }
        //     ]
        //   },
        //   {
        //     label: 'ikruser',
        //     options: [
        //       { value: '03', text: 'UZO' },
        //       { value: '04', text: 'NORD' },
        //       { value: '05', text: 'TIK' },
        //       { value: '06', text: '口红' },
        //       { value: '07', text: '510pen' },
        //       { value: '08', text: 'TIK PRO' },
        //       { value: '09', text: 'D019' },
        //       { value: '0A', text: 'CREST ' },
        //       { value: '0B', text: 'D022' },
        //     ]
        //   },
        //   {
        //     label: 'unicore',
        //     options: [
        //       { value: '03', text: 'ALLY'},
        //       { value: '04', text: '2ML'},
        //       { value: '05', text: 'YT009'},
        //       { value: '06', text: 'LG'},
        //       { value: '07', text: 'DM06'},
        //       { value: '08', text: 'DM026'},
        //       { value: '09', text: 'DM004C'},
        //       { value: '0A', text: 'CY122'},
        //     ]
        //   },
        //   {
        //     label: 'DM',
        //     options: [
        //       { value: '01', text: 'DM064' },
        //       { value: '02', text: 'DM023' },
        //     ]
        //   },
        // ],
      },
      form: {
        orderName: "",
        testSmokeBombModeId: "",
        batchQuantity: "",
        pcbaSupplier: "", // PCBA供应商
        chargerOfPcba: "", // 本次PCBA负责人
        assemblySupplier: "", // 组装供应商
        chargerOfAssembly: "", // 本次组装责任人
        output: "", // 六点输出
        manufacturerEncodingName: "",
        productionTime: "",
        remarks: "",
        defaultWarmHeatingId: "",
        defaultWarmUpId: "",
        type: "",
        maxFrequency: 2000,
        smokeBombModelId: "",
        productionType: "",
        batchType: 1,
      },
      productionType: 1,
      outputList: [
        { value: 2.8 },
        { value: 2.8 },
        { value: 2.8 },
        { value: 2.8 },
        { value: 2.8 },
        { value: 2.8 },
        { value: 2.8 },
        { value: 2.8 },
      ],
      min: 0.1,
      max: 4.2,
      step: 0.1,
      productionTime: "",
    };
  },
  methods: {
    init() {
      this.$api.Dropdown.selectAllTestSmokeBombModel({
        type: this.selectItem,
      }).then((res) => {
        if (res.success && res.data) {
          this.types.selectAllTestSmokeBombModel =
            this.types.selectAllTestSmokeBombModel.concat(res.data);
        } else {
          if (res.message) {
            this.$bvToast.toast(res.message);
          }
        }
      });
      if (this.selectItem == 2) {
        //默认加热模式
        this.$api.Dropdown.selectDefaultHeatingMode().then((res) => {
          if (res.success && res.data) {
            this.types.selectAllDefaultHeatingMode =
              this.types.selectAllDefaultHeatingMode.concat(res.data);
          } else {
            if (res.message) {
              this.$bvToast.toast(res.message);
            }
          }
        });
        //默认预热模式
        this.$api.Dropdown.selectDefaultWarmUpMode().then((res) => {
          if (res.success && res.data) {
            this.types.selectAllDefaultWarmUpMode =
              this.types.selectAllDefaultWarmUpMode.concat(res.data);
          } else {
            if (res.message) {
              this.$bvToast.toast(res.message);
            }
          }
        });
      }
      this.$api.Dropdown.selectModelBySupplierId({}).then((res) => {
        if (res.success) {
          this.types.flavorList = this.types.flavorList.concat(res.data);
        } else {
          if (res.message) {
            this.$bvToast.toast(res.message);
          }
        }
      });
    },
    changeoutputList(data) {
      let arr = [];
      data.forEach((item) => {
        if (item.value) {
          arr.push(item.value);
        }
      });
      this.form.output = arr.join(",");
    },

    changeProductType(productionType) {
          alert(productionType);
    },
    onSubmit(evt) {
      evt.preventDefault();
      if (!this.form.batchType) {
        this.$bvToast.toast("Please select batch Type.");
        return;
      }
      this.form.type = this.selectItem; //烟弹类型
      if (this.selectItem == 1) {
        this.form.maxFrequency = "";
      }
      if (this.selectItem == 4) {
        this.form.productionType = this.productionType;
        if (this.form.productionType == 2) {
          this.form.batchType = 1;
        }
        this.form.output = this.outputList
          .map((item) => item.value * 1000)
          .join(",");
      }
      this.$api.emptyCartridges.smokeRecordOrderSave(this.form).then((res) => {
        if (res.success) {
          this.$router.go(-1);
        } else {
          if (res.message) {
            this.$bvToast.toast(res.message);
          }
        }
      });
    },
    onReset(evt) {
      evt.preventDefault();
      this.$router.go(-1);
    },
    dateFormatter(val) {
      let dateTime = new Date(val);
      let year = dateTime.getFullYear();
      let month = dateTime.getMonth() + 1;
      let day = dateTime.getDate();
      // let h = dateTime.getHours();
      // let mm = dateTime.getMinutes();
      // let s = dateTime.getSeconds();
      return (
        year + "-" + this.appendZero(month) + "-" + this.appendZero(day)
        // + " " +
        // this.appendZero(h) +
        // ":" +
        // this.appendZero(mm) +
        // ":" +
        // this.appendZero(s)
      );
    },
    appendZero(obj) {
      if (obj < 10) return "0" + "" + obj;
      else return obj;
    },
  },
};
</script>
<style lang="scss" scoped>
.required-icon {
  ::before {
    content: "*";
  }
}

::v-deep .van-slider {
  margin: 10px auto;
}

.slider-button {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #ffffff;
}

.voltage-two {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;

  .voltage-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 150px;

    .voltage-plus {
      margin-bottom: 20px;
    }

    .voltage-reduce {
      margin-top: 16px;
    }
  }
}
.voltage-one {
  margin-top: 10px;
  margin-bottom: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  div {
    margin: 0 auto;
    font-size: 13px;
    font-weight: 400;
    color: #999999;
  }
}
.voltage-three {
  margin-top: 66px;
  margin-bottom: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;

  div {
    margin: 0 auto;
    font-size: 13px;
    font-weight: 400;
    color: #999999;
  }
}
.temperature {
  display: flex;
  flex-direction: column;
  align-items: center;
}
::v-deep .el-slider__button {
  border: 2px solid #86a1a9;
}
::v-deep .el-slider__bar {
  background: #86a1a9;
}
</style>


